:root {
  --body-text-color: #4B0082;
  --body-background-color: #E6E6FA;
  --bright-background-color: #fff;

  --highlight-color: #8800ff;
  --mild-color: #8400ff21;
  --contrast-color: #5AFF15;
  --muted-color: #6c757d;

  --rounded-corner: 0.25rem;
  --font-chunky: "Bungee", sans-serif;
  --font-pop: "Cyberthrone", serif;

  --primary-button-background-color: #3581B8;
  --primary-button-text-color: #ffffff;
  --primary-button-border-color: #2c6a96;
  --primary-button-hover-color: #1d4969;
  --primary-button-click-color: #163850;

}

@font-face {
  font-family: 'Cyberthrone';
  src: url('./fonts/Cyberthrone.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: var(--body-background-color);
  color: var(--body-text-color);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
}

#header {
  padding: 1rem 3rem 1rem;
  background: var(--bright-background-color);
  border-bottom: 1px solid var(--mild-color);
}

#logo-slay {
  transform: rotate(-20deg); 
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--highlight-color);
}

#logo-week{
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-left: -2rem;
  border-bottom: 1px solid var(--contrast-color);
}

#content {
  padding: 3rem 3rem 5rem;
  min-height: 50vh;
}

#footer {
  padding: 3rem;
}

#new-and-counter {
  gap: 3rem;
}

#new-todo {
  input[type="text"] {
    padding: 0 1rem;
    font-size: 1.25rem;
    border: 1px solid var(--mild-color);
    border-radius: 0.25rem;
    height: 100%;
  }
  .input-button-wrapper {
    height: 3rem;
  }
}

#week-slayed {
  opacity: 0;
  color: var(--contrast-color);
  font-weight: bold;
  font-size: 4rem;
  transition: all 0.5s ease;
  transform: scale(5) rotate(-5deg);
  z-index: -1;
  &.show {
    z-index: 1;
    opacity: 1;
    transform: scale(1) rotate(-5deg);
  }
}

#slay-counter {
  background-color: var(--bright-background-color);
  border: 1px solid var(--mild-color);
  text-align: center;
  overflow: hidden;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  .goal,.current {
    padding: 0.5rem;
    min-width: 100px;
    &.slay-counter-values {
      padding: 0.75rem 0.5rem;
    }
  }
  .current {
    border-right: 1px solid var(--mild-color);
  }
}

.slay-counter-header {
  background-color: var(--body-text-color);
  color: var(--bright-background-color);
}
.slay-counter-values {
  color: var(--highlight-color);
  font-size: 2rem;
  font-weight: bold;
  align-items: center;
  &.goal {
    font-size: 1.5rem;
  }
}


#lists {
  gap: 2.5rem;
  margin-top: 5rem;

  .list-title {
    font-size: 3rem;
    color: var(--highlight-color);
    text-transform: uppercase;
    font-weight: bold;
    margin-top: -2rem;
    padding: 0 1rem;
    transform: rotate(-5deg); 
    margin-bottom: 1rem;
    width: 200px;
  }
  .list {
    background-color: var(--bright-background-color);
    min-width: 200px;
    min-height: 300px;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.2s ease;

    &.dragged-over {
      border: 2px dashed var(--highlight-color);
      transform: scale(1.1);
    }

    .list-item {
      margin-left: 1rem;
      border-bottom: 1px solid var(--mild-color);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .checkmark {
        padding-right: 0.25rem;
      }
      .list-item-text{
        padding: 0.5rem 0;
      }
    }
    .list-end-dropzone {
      padding-bottom: 2rem;
    }
  }
}

#animated-checkmark{
  justify-self: center;
  opacity: 0;
  transition: all 1s ease;
  &.animating {
    opacity: 1;
    transform: scale(1.2);
  }
}

/* Utilities */
.color-muted {
  color: var(--muted-color);
}
.display-flex {
  display: flex;
}
.display-grid {
  display: grid;
}
.flex-grow-1 {
  flex-grow: 1;
}
.rounded-corners {
  border-radius: var(--rounded-corner);
}
.flex-wrap {
  flex-wrap: wrap;
}
.border-mild {
  border: 1px solid var(--mild-color);
}
.font-pop {
  font-family: var(--font-pop);
  letter-spacing: 0.25rem;
}
.font-chunky {
  font-family: var(--font-chunky);
}
.flex-between {
  justify-content: space-between;
  align-items: center;
}
.flex-direction-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.overlap {
  display: grid;
  > div {
    grid-column: 1;
    grid-row: 1;
  }
}
.pointer {
  cursor: pointer;
}
.grab {
  cursor: grab;
}
.height-100 {
  height: 100%;
}
.primary-button {
  background-color: var(--primary-button-background-color);
  color: var(--primary-button-text-color);
  border: 1px solid var(--primary-button-border-color); 
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.2s ease;
  cursor: pointer;
  border-radius: var(--rounded-corner);
  font-family: var(--font-chunky);
  padding: 1rem;
}

.primary-button:hover {
  background-color: var(--primary-button-hover-color);
}

.primary-button:active {
  background-color: var(--primary-button-click-color);
}

.input-button-wrapper {
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
  }
  button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
